<template>
    <div class="main__container main__padding" v-if="token">
        <div class="row__spacebetween row__spacebetween--wrap m-2-5-vh">
            <h2 class="m-0 mb-15" style="white-space: nowrap">
                Chat Termin: {{ myChat.company }}
            </h2>
            <a
                class="button button--big"
                id="cancel-chat-button"
                @click.stop="modalOpen = true"
            >
                <p class="text__white">Chat beenden</p>
            </a>
        </div>
        <ChatBox />
        <div class="modal__smallLayout" v-if="modalOpen">
            <div
                class="modal__small column__center"
                v-click-outside="closeModal"
            >
                <p class="mt-25 mb-50">Wollen Sie den Chat wirklich beenden?</p>
                <div class="row__center mb-25">
                    <div
                        class="button button--reverse mr-25"
                        @click="modalOpen = false"
                    >
                        <p class="text__primary text__semibold">Abbrechen</p>
                    </div>
                    <div class="button" @click.stop="prepareLeaveChat">
                        <p class="text__white">Beenden</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
export default {
    name: "Chat",
    data() {
        return {
            modalOpen: false,
            isLoading: false,
        };
    },
    methods: {
        ...mapActions(["receiveChat", "sendMessage"]),
        async prepareLeaveChat() {
            await this.sendMessage({
                id: this.$router.currentRoute.params.id,
                message: "Interessent*in hat den Chat verlasssen",
                status: "system",
                code: this.$route.query.code
            });
            this.$router.push({
                name: "Calendar",
            });
        },
        closeModal() {
            this.modalOpen = false;
        },
    },
    created() {
        if (!this.token) {
            this.$router.push({
                name: "Login",
            });
        }
    },
    computed: {
        ...mapGetters(["token", "myChat"]),
    },
    directives: {
        ClickOutside,
    },
    async mounted() {
        await this.receiveChat({appointmentId: this.$router.currentRoute.params.id, code: this.$route.query.code});
    },
};
</script>
